import * as React from 'react';
import { CEE } from 'api/cee/types';
import * as cee from 'api/cee';
import * as ceeV3 from 'api/cee/cee-v3';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AppContext from 'AppContext';
import I18nContext from 'I18nContext';
import styled from 'styled-components';
import Button, { COLOR } from 'components/Button';
import Modal from 'components/Modal';
import { useSnackbar } from 'notistack';
import { logError, logInfo } from 'utils/logger';
import { MyPageError } from 'MyPageError';

enum SubscriptionStatus {
  Active = 1,
  WithTimeLeft,
  NotActive,
}

enum Modals {
  SubscriptionsActive = 1,
  SubscriptionsWithTimeLeft,
  ConfirmEmail,
  AccountDeleted,
  AccountAlreadyDeleted,
}

const Email = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const Input = styled.input`
  outline: none;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 13px;
  width: 100%;
  height: 46px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid transparent;
  padding: 0 20px;
  transition: border-color ${({ theme }) => theme.transitionTiming};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryLight};
  }
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.border};
    &:hover {
      border-color: ${({ theme }) => theme.colors.border};
    }
  }
`;

const Label = styled.label`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 7px;
`;

const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const getModalToShow = (subscriptionStatus: SubscriptionStatus | null) => {
  if (subscriptionStatus === SubscriptionStatus.Active) {
    return Modals.SubscriptionsActive;
  }

  if (subscriptionStatus === SubscriptionStatus.WithTimeLeft) {
    return Modals.SubscriptionsWithTimeLeft;
  }

  return Modals.ConfirmEmail;
};

const getSubscriptionStatus = (
  status: 'Active' | 'Cancelled' | 'Inactive' | null,
  endDate: string | null | undefined
) => {
  if (status === 'Cancelled' && moment(endDate).isBefore(moment())) {
    return SubscriptionStatus.NotActive;
  }

  if (status === 'Cancelled' && moment(endDate).isAfter(moment())) {
    return SubscriptionStatus.WithTimeLeft;
  }

  if (status === 'Active' && moment(endDate).isAfter(moment())) {
    return SubscriptionStatus.Active;
  }

  return SubscriptionStatus.NotActive;
};

type DeleteAccountProps = {
  supporter: CEE.Supporter;
  onClose: () => void;
  onLoading: (loading: boolean) => void;
};

const DeleteAccount = ({ supporter, onClose, onLoading }: DeleteAccountProps) => {
  const { tokens, signOut } = React.useContext(AppContext);
  const { strings } = React.useContext(I18nContext);
  const { enqueueSnackbar } = useSnackbar();
  const [emailVerificationInput, setEmailVerificationInput] = React.useState('');
  const [modalToShow, setModalToShow] = React.useState<Modals | null>(null);
  const [counter, setCounter] = React.useState(0);
  const [subscriptionProducts, setSubscriptionProducts] = React.useState<
    CEE.PersonProductV3[] | null
  >(null);
  const [highlightsPlusSubscription, setHighlightsPlusSubscription] =
    React.useState<CEE.Subscription | null>(null);
  const [activeSubscriptions, setActiveSubscriptions] = React.useState<
    CEE.PersonProductV3[] | null
  >(null);
  const [loadingClubSubscriptions, setLoadingClubSubscriptions] = React.useState(true);
  const [loadingHighlightsSubscription, setLoadingHighlightsSubscription] = React.useState(true);
  const [deleteingAccount, setDeletingAccount] = React.useState(false);
  const history = useHistory();

  const loading = loadingClubSubscriptions || loadingHighlightsSubscription;

  const fetchClubSubscriptions = React.useCallback(async () => {
    if (!tokens) return;
    setLoadingClubSubscriptions(true);
    try {
      const products = await ceeV3.getPersonSubscriptionProducts(
        tokens.PersonId,
        tokens.accessToken
      );
      setSubscriptionProducts(products);
    } catch (error) {
      console.error(error);
    }
    setLoadingClubSubscriptions(false);
  }, []);

  const fetchHighlightPlusSubscription = React.useCallback(async () => {
    if (!tokens) return;
    setLoadingHighlightsSubscription(true);
    try {
      const subscription = await cee.getSubscription(tokens.PersonId, {
        accessToken: tokens.accessToken,
      });
      setHighlightsPlusSubscription(subscription);
    } catch (error) {
      console.error(error);
    }
    setLoadingHighlightsSubscription(false);
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      fetchHighlightPlusSubscription();
      fetchClubSubscriptions();
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    onLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    if (subscriptionProducts || highlightsPlusSubscription) {
      let subscriptionsActive = null;
      let subscriptionsWithTimeLeft = null;
      let highlightsPlusSubscriptionStatus: SubscriptionStatus | null = null;

      if (highlightsPlusSubscription) {
        highlightsPlusSubscriptionStatus = getSubscriptionStatus(
          highlightsPlusSubscription.Status,
          highlightsPlusSubscription.SubscriptionEndDate
        );
      }

      if (subscriptionProducts) {
        subscriptionsActive = subscriptionProducts.filter(
          (subscription) =>
            getSubscriptionStatus(subscription.Status, subscription.SubscriptionNextRenewalDate) ===
            SubscriptionStatus.Active
        );
        subscriptionsWithTimeLeft = subscriptionProducts?.filter(
          (subscription) =>
            getSubscriptionStatus(subscription.Status, subscription.SubscriptionNextRenewalDate) ===
            SubscriptionStatus.WithTimeLeft
        );
      }

      let subscriptionStatus = highlightsPlusSubscriptionStatus;
      let newActiveSubscriptions = null;

      if (
        subscriptionStatus !== SubscriptionStatus.NotActive &&
        subscriptionsActive &&
        subscriptionsActive.length > 0
      ) {
        subscriptionStatus = SubscriptionStatus.Active;
        newActiveSubscriptions = subscriptionsActive;
      }

      if (
        subscriptionStatus !== SubscriptionStatus.Active &&
        subscriptionsWithTimeLeft &&
        subscriptionsWithTimeLeft.length > 0
      ) {
        subscriptionStatus = SubscriptionStatus.WithTimeLeft;
        newActiveSubscriptions = subscriptionsWithTimeLeft;
      }

      setModalToShow(getModalToShow(subscriptionStatus));
      setActiveSubscriptions(newActiveSubscriptions);
    }
  }, [subscriptionProducts, highlightsPlusSubscription]);

  React.useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      timer && clearInterval(timer);
    };
  }, [counter]);

  const onCloseModal = () => {
    setModalToShow(null);
    onClose();
  };

  const logoutUser = () => {
    setInterval(async () => {
      signOut();
    }, 10000);
  };

  const startDeleteAccount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const email = supporter?.Email;

    if (email && tokens) {
      setDeletingAccount(true);

      try {
        const result = await cee.deleteAccount(email, { accessToken: tokens.accessToken });

        if (result?.statusCode === 200) {
          setModalToShow(Modals.AccountDeleted);
          setCounter(10);
          logoutUser();
        }
        if (result?.statusCode === 400) {
          setModalToShow(Modals.AccountAlreadyDeleted);
          setCounter(10);
          logoutUser();
        }
        if (result?.statusCode === 404) {
          throw new MyPageError(404, 'Account not found');
        }

        logInfo('DeleteAccount - User deleted successfully', {
          personId: supporter.Id,
        });
      } catch (error) {
        const myPageError = error as MyPageError;
        logError('DeleteAccount - Failed deleting user', {
          personId: supporter.Id,
          errorMessage: myPageError,
        });
        enqueueSnackbar(strings.accountDeletionFailed, { variant: 'error' });
      }

      setDeletingAccount(false);
    }
  };

  const redirectToSubscriptions = () => {
    history.push('/subscription');
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {modalToShow === Modals.SubscriptionsActive && (
        <Modal
          onClose={onCloseModal}
          title={strings.removeAccount}
          actions={[
            <Button
              key="1continueWithAccount"
              color={COLOR.OUTLINED}
              onClick={() => setModalToShow(null)}
            >
              {strings.continueHavingAccount}
            </Button>,
            <br key="Del2" />,
            <Button key="Del1" color={COLOR.SUCCESS} onClick={() => redirectToSubscriptions()}>
              {strings.toSubscription}
            </Button>,
          ]}
        >
          <>
            <p>{strings.confirmCancelAccountText1}</p>
            <p>{strings.confirmCancelAccountText2}</p>
            <p>{strings.needToCancelSubscription}</p>
            <p>{strings.activeSubscriptions}</p>
            <ul>
              {highlightsPlusSubscription?.Status === 'Active' && (
                <li>
                  {strings.activeSubscription.replace('{{product}}', 'Highlights Plus')}
                  <Bold>
                    {moment(highlightsPlusSubscription.SubscriptionEndDate).format('YYYY-MM-DD')}
                  </Bold>
                </li>
              )}
              {activeSubscriptions &&
                activeSubscriptions.map((subscription, index) => (
                  <li key={index}>
                    {strings.activeSubscription.replace(
                      '{{product}}',
                      subscription.Product.DisplayName || ''
                    )}
                    <Bold>
                      {moment(subscription.SubscriptionNextRenewalDate).format('YYYY-MM-DD')}
                    </Bold>
                  </li>
                ))}
            </ul>
            <p>
              {strings.confirmCancelText4}{' '}
              <Email href={`mailto: ${strings.supportEmail}`}>{strings.supportEmail}</Email>
            </p>
          </>
        </Modal>
      )}

      {modalToShow === Modals.SubscriptionsWithTimeLeft && (
        <Modal
          onClose={() => setModalToShow(null)}
          title={strings.removeAccount}
          actions={[
            <Button
              key="Del3"
              color={COLOR.OUTLINED}
              onClick={() => setModalToShow(Modals.ConfirmEmail)}
            >
              {strings.continueToDeleteAccount}
            </Button>,
            <br key="Del4" />,
            <Button key="Del5" color={COLOR.SUCCESS} onClick={() => setModalToShow(null)}>
              {strings.continueHavingAccount}
            </Button>,
          ]}
        >
          <>
            <p>{strings.confirmCancelAccountText1}</p>
            <p>{strings.timeLeftToUseHighlightsPlus1}</p>
            <ul>
              {highlightsPlusSubscription?.Status === 'Active' && (
                <li>
                  {strings.activeSubscription.replace('{{product}}', 'Highlights Plus')}
                  <Bold>
                    {moment(highlightsPlusSubscription.SubscriptionEndDate).format('YYYY-MM-DD')}
                  </Bold>
                </li>
              )}
              {activeSubscriptions &&
                activeSubscriptions.map((subscription, index) => (
                  <li key={index}>
                    {strings.activeSubscription.replace(
                      '{{product}}',
                      subscription.Product.DisplayName || ''
                    )}
                    <Bold>
                      {moment(subscription.SubscriptionNextRenewalDate).format('YYYY-MM-DD')}
                    </Bold>
                  </li>
                ))}
            </ul>
            <p>
              {strings.confirmCancelText4}{' '}
              <Email href={`mailto: ${strings.supportEmail}`}>{strings.supportEmail}</Email>
            </p>
          </>
        </Modal>
      )}

      {modalToShow === Modals.ConfirmEmail && (
        <Modal
          onClose={onCloseModal}
          title={strings.removeAccount1}
          actions={[
            <Button
              key="Del6"
              disabled={emailVerificationInput !== supporter?.Email}
              loading={deleteingAccount}
              color={COLOR.SUCCESS}
              onClick={startDeleteAccount}
            >
              {strings.confirm}
            </Button>,
          ]}
        >
          <>
            <p>{strings.confirmRemoveAccount}</p>
            <Label htmlFor="email">{strings.verifyByEmail}</Label>
            <Input
              id="email"
              name="email"
              onChange={(e) => setEmailVerificationInput(e.target.value)}
              type="text"
              value={emailVerificationInput}
            />
          </>
        </Modal>
      )}

      {modalToShow === Modals.AccountDeleted && (
        <Modal onClose={onCloseModal} title={strings.accountDeleted}>
          <>
            <p>{strings.confirmEmailHasBeenSent}</p>
            <p>{strings.logoutIn.replace('{s}', counter.toString())}</p>
          </>
        </Modal>
      )}

      {modalToShow === Modals.AccountAlreadyDeleted && (
        <Modal onClose={onCloseModal} title={strings.somethingFailed}>
          <>
            <p>{strings.alreadyDeleted}</p>
            <p>{strings.logoutIn.replace('{s}', counter.toString())}</p>
          </>
        </Modal>
      )}
    </>
  );
};

export default DeleteAccount;
